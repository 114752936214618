<template>
  <div class="examine_pay_container">
    <div>支&nbsp;付&nbsp;中...</div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Pay",
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      this.$router.push({
        path: "/examine/status"
      });
    }, 1000);
  },
  methods: {}
};
</script>

<style scope>
.examine_pay_container {
  font-size: 100px;
  color: rgb(13, 190, 13);
  font-weight: 700;
}
.examine_pay_container div {
  position: fixed;
  top: 30%;
  left: 53%;
  transform: translate(-50%);
}
</style>